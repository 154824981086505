@import 'src/utils/utils';

.message {
  @include Text-16-reg;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: $Alternative-9;

  @media(min-width: $md) {
    padding: 20px;
    border-radius: 20px;
  }

  p {
    margin: 0;
  }

  a {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    text-decoration: none;
    color: $Corporate-2;

    @media(min-width: $lg) {
      padding-left: 28px;
    }

    &:hover {
      @media(min-width: $lg) {
        text-decoration: underline;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/link.svg');
      background-size: contain;
      background-position: center;
      content: '';

      @media(min-width: $lg) {
        top: 2px;
        width: 24px;
        height: 24px;
      }
    }
  }

  ul,
  ol {
    display: grid;
    margin: 0;
    padding: 0;
    list-style: none;
    row-gap: 5px;

    li {
      position: relative;
      padding-left: 16px;

      &:before {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $Main-1;
        content: '';
        top: 7px;
        left: 0;

        @media(min-width: $lg) {
          top: 10px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media(min-width: $md) {
      gap: 15px;
    }
  }
}